import React, { useState } from 'react';
import { Paper, Grid, Button, Box, Modal, Typography } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';
import AssetAlignmentModalContent from '../AssetAlignmentModalContent';
import AssetAlarmingModalContent from '../AssetAlarmingModalContent';
import FormikSwitch from '../../Generic/FormikSwitch';
import TextRotationAngledownIcon from '@mui/icons-material/TextRotationAngledown';
import NotificationsIcon from '@mui/icons-material/Notifications';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
export default function FormikNodeAssignment(props) {

    const [alignModalOpen, setAlignModalOpen] = useState(false);
    const [alarmModalOpen, setAlarmModalOpen] = useState(false);

    const handleAlignModalOpen = () => setAlignModalOpen(true);
    const handleAlignModalClose = () => setAlignModalOpen(false);
    const handleAlarmModalOpen = () => setAlarmModalOpen(true);
    const handleAlarmModalClose = () => setAlarmModalOpen(false);

    const handleOrientationChange = (event, posIdx) => {
        props.formik?.setFieldValue(props.name + '.axisAlignment', event.target.value)
    }

    // Handle changes in alarming
    const handleAlarmingChange = (event, changeType, posIdx) => {
        switch (changeType) {
            case "WarningThreshold":
                let newWarningThreshold = event.target.value;
                newWarningThreshold = (newWarningThreshold > 2) ? 2 : (newWarningThreshold < 0) ? 0 : newWarningThreshold;
                props.formik?.setFieldValue(props.name + '.warningThreshold', newWarningThreshold);
                break;
            case "WarningThresholdRecipients":
                props.formik?.setFieldValue(props.name + '.warningRecipientIDs', event.target.value);
                break;
            case "DangerThreshold":
                let newDangerThreshold = event.target.value;
                newDangerThreshold = (newDangerThreshold > 2) ? 2 : (newDangerThreshold < 0) ? 0 : newDangerThreshold;
                props.formik?.setFieldValue(props.name + '.dangerThreshold', newDangerThreshold);
                break;
            case "DangerThresholdRecipients":
                props.formik?.setFieldValue(props.name + '.dangerRecipientIDs', event.target.value);
                break;
        }
    }

    const filterAssignedNodes = (node) => {
        if(node.value == 0){
            return true;
        }
        if (node.value == props.formik?.values.p1AssignedNode.nodeID && props.name != 'p1AssignedNode') {
            return false;
        }
        if (node.value == props.formik?.values.p2AssignedNode.nodeID && props.name != 'p2AssignedNode') {
            return false;
        }
        if (node.value == props.formik?.values.p3AssignedNode.nodeID && props.name != 'p3AssignedNode') {
            return false;
        }
        if (node.value == props.formik?.values.p4AssignedNode.nodeID && props.name != 'p4AssignedNode') {
            return false;
        }
        return true;
    }

    return (
        <Paper elevation={2} sx={{ position: 'relative', width: '100%', margin: 'auto', p: 1 }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
                spacing={2}
            >
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={props.name + '.nodeID'}
                        label={props.label}
                        data={props.unassignedNodes.filter(filterAssignedNodes)}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors[props.name]?.nodeID}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                {props.formik?.values[props.name].nodeID != 0 && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={props.name + '.shaftSpeedSource'}
                        label={'Shaft Speed Source'}
                        data={props.enums.shaftSpeedSources}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors[props.name]?.shaftSpeedSource}
                        width={'100%'}
                        enabled={!props.formik?.values.disableShaftSpeedSource}
                    />
                </Grid>}
                {props.formik?.values[props.name].nodeID != 0 && <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" fullWidth onClick={handleAlignModalOpen} endIcon={<TextRotationAngledownIcon />}>Set Axis Alignment</Button>
                    <Modal
                        open={alignModalOpen}
                        onClose={handleAlignModalClose}
                        aria-labelledby="align-modal-title"
                        aria-describedby="align-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <AssetAlignmentModalContent
                                enums={props.enums}
                                positionIdx={props.position}
                                value={props.formik?.values[props.name]?.axisAlignment}
                                onChange={handleOrientationChange}
                                closeModals={handleAlignModalClose}
                            />
                        </Box>
                    </Modal>
                </Grid>}
                {props.formik?.values[props.name].nodeID != 0 && <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                    <Button variant="contained" fullWidth onClick={handleAlarmModalOpen} endIcon={<NotificationsIcon />}>Set Alarms</Button>
                    <Modal
                        open={alarmModalOpen}
                        onClose={handleAlarmModalClose}
                        aria-labelledby="alarm-modal-title"
                        aria-describedby="alarm-modal-description"
                    >
                        <Box sx={modalStyle}>
                            <AssetAlarmingModalContent
                                enums={props.enums}
                                users={props.users}
                                userGroups={props.userGroups}
                                positionIdx={props.position}
                                dangerThreshold={props.formik?.values[props.name]?.dangerThreshold}
                                dangerRecipientIDs={props.formik?.values[props.name]?.dangerRecipientIDs}
                                warningThreshold={props.formik?.values[props.name]?.warningThreshold}
                                warningRecipientIDs={props.formik?.values[props.name]?.warningRecipientIDs}
                                handleAlarmingChange={handleAlarmingChange}
                                closeModals={handleAlarmModalClose}
                            />
                        </Box>
                    </Modal>
                </Grid>}
                {props.formik?.values[props.name].nodeID != 0 && <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={props.name + '.xEnabled'}
                        label={'X Axis Enabled'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors[props.name]?.xEnabled}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {props.formik?.values[props.name].nodeID != 0 && <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={props.name + '.yEnabled'}
                        label={'Y Axis Enabled'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors[props.name]?.yEnabled}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {props.formik?.values[props.name].nodeID != 0 && <Grid item xs={2} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={props.name + '.zEnabled'}
                        label={'Z Axis Enabled'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors[props.name]?.zEnabled}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
                {props.formik?.errors[props.name] && typeof (props.formik?.errors[props.name]) != 'object' &&
                <Grid item xs={12} display="flex" justifyContent="flex-start" alignItems="center">
                    <Typography color="red">{props.formik?.errors[props.name]}</Typography>
                </Grid>}
            </Grid>
        </Paper>
    )
}
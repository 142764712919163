import React from 'react';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import PositionAngle from './PositionAngle';
import BigAssetImage from './BigAssetImage';


export default function PhaseDefect({ingredient, phaseAngleData, machineType, axisOrientationTypes })
{
    let p1 = phaseAngleData.find(item => item.positionID == 1);
    let p2 = phaseAngleData.find(item => item.positionID == 2);
    let p3 = phaseAngleData.find(item => item.positionID == 3);
    let p4 = phaseAngleData.find(item => item.positionID == 4);
    let positions = [p1, p2, p3, p4];

    const renderPositionGraph = (position) => {
        return (
            <Grid item xs={4}>
                <Paper>
                    <Typography>{position.position}</Typography>
                    <PositionAngle
                        ingredient={ingredient}
                        phaseAngles={position.phaseAngles}
                        axisOrientationTypes={axisOrientationTypes}
                    />
                </Paper>
            </Grid>
        );
    }

    const renderEmptyPosition = (position) => {
        return (
            <Grid item xs={2}>
                <Paper sx={{ height: '100%', padding: 2 }}>
                    <Grid container direction="row" sx={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Typography>{position?.position}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Extraneous for this defect.</Typography>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Typography variant="h5" style={styles.description}>
                {ingredient.IngredientDescription}
            </Typography>
            {/* Stack of charts rendered below the description */}
            {phaseAngleData.length > 0 ? (< Grid
                container
                direction="row"
                alignItems="stretch"
                spacing={2}
            >
                {positions.map((pos) => {
                    return pos.phaseAngles.find(angle => angle.isHighlighted) !== undefined ? (renderPositionGraph(pos)) : (renderEmptyPosition(pos))
                })}
                <Grid item xs={12}>
                    <br />
                    <Paper>
                        <BigAssetImage
                            machineType={machineType}
                        />
                    </Paper>

                </Grid>
            </Grid>) : (<Typography sx={{ my: "10%" }}>No data present for this ingredient.</Typography>) }
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        marginBottom: '16px', // Space between the description and the charts
    }
};

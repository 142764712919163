import { VBTxConfigurationInitialValues } from "../Models/VBTxConfigurationInitialValues"
import { numberToBase64 } from "../../NodeSettings/NodeSettingsHelpers"

//Maps an enum from the database into a format digestible by formik
export const mapEnumToFormik = (type) => {
    return ({
        "value": type.ID,
        "label": type.DisplayName
    });
}

//Select the appropriate defect level based on analysis type
const mapAnalysisType = (analysisType) => {
    if (analysisType != 3) {
        return analysisType
    } else {
        return 2;
    }
}

//Return an node with its setting configured
const assignedNodeInit = (node) => {
    return {
        "nodeID": node ? node.NodeID : 0,
        "axisAlignment": node ? node.AxisAlignment : 0,
        "shaftSpeedSource": node ? node.ShaftSpeedSource : 1,
        "xEnabled": node ? node.XEnabled : true,
        "yEnabled": node ? node.YEnabled : true,
        "zEnabled": node ? node.ZEnabled : true,
        "warningThreshold": node ? node.WarningThreshold : 0.1,
        "dangerThreshold": node ? node.DangerThreshold : 0.3,
        "warningRecipientIDs": node ? node.WarningRecipientIDs : [],
        "dangerRecipientIDs": node ? node.DangerRecipientIDs : [],
    }
}

const defaultNodeConfig = {
    "nodeID": 0,
    "axisAlignment": 0,
    "shaftSpeedSource": 1,
    "xEnabled": true,
    "yEnabled": true,
    "zEnabled": true,
    "warningThreshold": 0.1,
    "dangerThreshold": 0.3,
    "warningRecipientIDs": [],
    "dangerRecipientIDs": [],
}

//Get the initial values for the form from the incoming node to edit or defaults if adding
export const getInitialValues = (vbtxAsset, editing) => {
    if (vbtxAsset) {
        return {
            "assetName": vbtxAsset.AssetName,
            "machineType": vbtxAsset.MachineType,
            "driveType": vbtxAsset.DriveType,
            //Don't load in the nodes in the clone asset case
            "p1AssignedNode": editing ? assignedNodeInit(vbtxAsset.p1AssignedNode) : defaultNodeConfig,
            "p2AssignedNode": editing ? assignedNodeInit(vbtxAsset.p2AssignedNode) : defaultNodeConfig,
            "p3AssignedNode": editing ? assignedNodeInit(vbtxAsset.p3AssignedNode) : defaultNodeConfig,
            "p4AssignedNode": editing ? assignedNodeInit(vbtxAsset.p4AssignedNode) : defaultNodeConfig,
            "gearboxLocation": vbtxAsset.GearboxLocation,
            "bladeCount": vbtxAsset.BladeCount,
            "inputToothCount": vbtxAsset.InputToothCount,
            "outputToothCount": vbtxAsset.OutputToothCount,
            "shaftSpeed": vbtxAsset.ShaftSpeed,
            "shaftSpeedLoBound": vbtxAsset.ShaftSpeedLoBound,
            "shaftSpeedHiBound": vbtxAsset.ShaftSpeedHiBound,
            "vibrationSamplingPeriod": vbtxAsset.VibrationSamplingPeriod,
            "temperatureSamplingPeriod": vbtxAsset.TemperatureSamplingPeriod,
            "triggerSource": vbtxAsset.TriggerSource,
            "threshold": vbtxAsset.Threshold,
            "maxTriggers": vbtxAsset.MaxTriggers,
            "defectLevel": vbtxAsset.DefectLevel,
            "maximizeNodes": vbtxAsset.MaximizeNodes,
            "disableShaftSpeedSource": vbtxAsset.DriveType == 0,
        }
    }
    else {
        return VBTxConfigurationInitialValues
    }
}

//Get the initial values for the form from the incoming config
export const getInitialValuesFromConfig = (vbtxConfig) => {
    let configs = vbtxConfig.reduce((acc, curr) => {
        acc[curr.displayName] = curr.optionValue;
        return acc;
    }, {});
    let node = {
        "nodeID": -1,
        "axisAlignment": Number(configs["Axis Alignment"]),
        "shaftSpeedSource": configs["Vibration Shaft Speed Source"],
        "xEnabled": configs["X Axis Enabled"] != 0,
        "yEnabled": configs["Y Axis Enabled"] != 0,
        "zEnabled": configs["Z Axis Enabled"] != 0,
        "warningThreshold": 0.1,
        "dangerThreshold": 0.3,
        "warningRecipientIDs":  [],
        "dangerRecipientIDs": [],
    };
    return {
        "assetName": "NULL",
        "machineType": 0,
        "driveType": -1,
        "p1AssignedNode": node,
        "p2AssignedNode": defaultNodeConfig,
        "p3AssignedNode": defaultNodeConfig,
        "p4AssignedNode": defaultNodeConfig,
        "gearboxLocation": 0,
        "bladeCount": configs["Blade/Gear Tooth Count"],
        "inputToothCount": 0,
        "outputToothCount": 0,
        "shaftSpeed": configs["Fixed RPM - Shaft Speed"],
        "shaftSpeedHiBound": configs["Variable Shaft Speed - Upper Bound"],
        "shaftSpeedLoBound": configs["Variable Shaft Speed - Lower Bound"],
        "vibrationSamplingPeriod": configs["Vibration Sampling Period"],
        "temperatureSamplingPeriod": configs["Temperature Sampling Period"],
        "triggerSource": configs["Vibration Analysis Trigger Source"],
        "threshold": configs["Vibration Analysis Trigger ADC Threshold"],
        "maxTriggers": configs["Vibration Sampling Period"] / configs["Minimum Minutes Between Vibration Analysis Triggers"],
        "defectLevel": mapAnalysisType(configs["Analysis Type"]),
        "maximizeNodes": (configs["Analysis Type"] == 2),
        "disableShaftSpeedSource": false
    }
}

//Convert formik values for node to object for API to handle
const getVibrationObjectNode = (node) => {
    return ({
        NodeID: Number(node.nodeID),
        AxisAlignment: Number(node.axisAlignment),
        ShaftSpeedSource: Number(node.shaftSpeedSource),
        XEnabled: node.xEnabled,
        YEnabled: node.yEnabled,
        ZEnabled: node.zEnabled,
        WarningThreshold: node.warningThreshold,
        DangerThreshold: node.dangerThreshold,
        WarningRecipientIDs: node.warningRecipientIDs,
        DangerRecipientIDs: node.dangerRecipientIDs,
    })
}

//Convert the defect level into an analysis type
const getAnalysisType = (formikValues) => {
    switch (formikValues.defectLevel) {
        case 0:
        case 1:
            return formikValues.defectLevel;
        case 2:
            if (formikValues.maximizeNodes) {
                return 2;
            } else {
                return 3;
            }
    }
}

//Create post object to send to API
export const createPostObject = (formikValues, editing, userID, companyID, objectID) => {
    const asset = {
        CompanyID: Number(companyID),
        UserID: Number(userID),
        ObjectID: editing ? Number(objectID) : null,
        AssetName: formikValues.assetName,
        MachineType: Number(formikValues.machineType),
        DriveType: Number(formikValues.driveType),
        p1AssignedNode: getVibrationObjectNode(formikValues.p1AssignedNode),
        p2AssignedNode: getVibrationObjectNode(formikValues.p2AssignedNode),
        p3AssignedNode: getVibrationObjectNode(formikValues.p3AssignedNode),
        p4AssignedNode: getVibrationObjectNode(formikValues.p4AssignedNode),
        GearboxLocation: formikValues.gearboxLocation,
        BladeCount: formikValues.bladeCount,
        InputToothCount: formikValues.inputToothCount,
        OutputToothCount: formikValues.outputToothCount,
        ShaftSpeed: formikValues.shaftSpeed,
        ShaftSpeedLoBound: formikValues.shaftSpeedLoBound,
        ShaftSpeedHiBound: formikValues.shaftSpeedHiBound,
        VibrationSamplingPeriod: formikValues.vibrationSamplingPeriod,
        TemperatureSamplingPeriod: formikValues.temperatureSamplingPeriod,
        TriggerSource: formikValues.triggerSource,
        Threshold: formikValues.threshold,
        MaxTriggers: formikValues.maxTriggers,
        DefectLevel: formikValues.defectLevel,
        MaximizeNodes: formikValues.maximizeNodes
    }
    return asset;
}

//Create post object to send to API
export const createPostObjectConfig = (formikValues, vbtxConfig) => {
    let configs = vbtxConfig.reduce((acc, curr) => {
        acc[curr.displayName] = {
            configurationOptionID: curr.configurationOptionID,
            length: curr.length,
        };
        return acc;
    }, {});
    let tags = [
        {
            tag: "Axis Alignment",
            value: formikValues.p1AssignedNode.axisAlignment
        },
        {
            tag: "Vibration Shaft Speed Source",
            value: formikValues.p1AssignedNode.shaftSpeedSource
        },
        {
            tag: "X Axis Enabled",
            value: formikValues.p1AssignedNode.xEnabled ? 1 : 0
        },
        {
            tag: "Y Axis Enabled",
            value: formikValues.p1AssignedNode.yEnabled ? 1 : 0
        },
        {
            tag: "Z Axis Enabled",
            value: formikValues.p1AssignedNode.zEnabled ? 1 : 0
        },
        {
            tag: "Blade/Gear Tooth Count",
            value: Number(formikValues.bladeCount)
        },
        {
            tag: "Fixed RPM - Shaft Speed",
            value: Number(formikValues.shaftSpeed)
        },
        {
            tag: "Variable Shaft Speed - Lower Bound",
            value: Number(formikValues.shaftSpeedLoBound) / 60
        },
        {
            tag: "Variable Shaft Speed - Upper Bound",
            value: Number(formikValues.shaftSpeedHiBound) / 60
        },
        {
            tag: "Vibration Sampling Period",
            value: formikValues.vibrationSamplingPeriod
        },
        {
            tag: "Temperature Sampling Period",
            value: formikValues.temperatureSamplingPeriod
        },
        {
            tag: "Vibration Analysis Trigger Source",
            value: formikValues.triggerSource
        },
        {
            tag: "Minimum Minutes Between Vibration Analysis Triggers",
            value: formikValues.triggerSource == 1 ? (formikValues.vibrationSamplingPeriod) : (formikValues.vibrationSamplingPeriod / formikValues.maxTriggers)
        },
        {
            tag: "Vibration Analysis Trigger ADC Threshold",
            value: formikValues.threshold
        },
        {
            tag: "Analysis Type",
            value: getAnalysisType(formikValues)
        },
        {
            tag: "Vibration Frequency Analysis Enabled",
            value: (formikValues.defectLevel == 2 && !formikValues.maximizeNodes) ? 1 : 0
        },
        {
            tag: "Vibration Phase Analysis Enabled",
            value: (formikValues.defectLevel == 2 && !formikValues.maximizeNodes) ? 1 : 0
        },
        {
            tag: "Acceleration Range",
            value: 0
        },
        {
            tag: "Burst Mode Trigger Source",
            value: 1
        },
        {
            tag: "Burst Mode Trigger ADC Threshold",
            value: 0
        },
        {
            tag: "Minimum Time Between Burst Mode Triggers",
            value: 3600
        },
        {
            tag: "Maximum Time Between Burst Mode Triggers",
            value: 3600
        },
        {
            tag: "Maximum Minutes Between Vibration Analysis Triggers",
            value: formikValues.vibrationSamplingPeriod
        },
    ];
    let configOptions = tags.map((tag) => {
        return {
            ConfigurationOptionID: configs[tag.tag].configurationOptionID,
            OptionByteString: numberToBase64(tag.value, configs[tag.tag].length)
        }
    })
    return configOptions
}
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function FrequencyDefectChart({ ingredient, positionData }) {
    const handleColumnClick = (dashboardDataID) => {
        const redirectUrl = new URL(document.referrer);
        redirectUrl.pathname = '/Data';
        redirectUrl.searchParams.set('NodeChannelIDs', dashboardDataID);
        window.top.location = redirectUrl;
    }
    
    const orderLines = [];
    for (let i = 0; i < 14; i++) {
        let color = '#bfbfbf';
        //How precise do we need to be?
        if (positionData.highlightedMultiple > (i - 0.1) && positionData.highlightedMultiple < (i + 0.1)) {
            color = 'green';
        }
        orderLines.push({
            value: i,
            color: color,
            width: 2,
            dashStyle: 'ShortDash',
        })
    }

    const chartOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        xAxis: {
            title: {
                text: 'Shaft Speed Multiple'
            },
            labels: {
                autoRotationLimit: 40
            },
            plotLines: orderLines,
            min: -0.5,
            max: 13.5
        },
        yAxis: {
            title: {
                text: `Vibration Amplitude (${ingredient.UnitTypeSymbol || 'ips'})`
            },
        },
        legend: {
            enabled: false, // Enable the legend           
        },
        tooltip: {
            formatter: function () {
                return `<b>${this.point.name}</b>: ${this.point.y.toFixed(3)} (ips)<br/> ${this.point.channelDisplayName}`;
            }
        },
        plotOptions: {
            column: {
                grouping: false, // Ensures that columns don't overlap if there are multiple series
                pointPadding: 0.5, // Padding between columns
                groupPadding: 0.0, // Padding between groups of columns
                point: {
                    events: {
                        click: function () {
                            handleColumnClick(this.options.dashboardDataID);
                        }
                    }
                }
            },
            scatter: {
                marker: {
                    radius: 4, 
                },
                enableMouseTracking: false, 
                pointPlacement: 'on'
            }
        },
        series: [
            ...positionData.seriesData.map((item) => ({
                type: 'column',
                data: [{
                    y: item.y,                 // The data point value
                    color: item.color,         // Use the specified color
                    x: item.x,
                    name: item.x + 'x RPM',
                    channelDisplayName: item.channelDisplayName,  // Add the channelDisplayName to the point data
                    dashboardDataID: item.dashboardDataID
                }],
                showInLegend: true,
                enableMouseTracking: true
            })),
            ...positionData.seriesData.map((item) => ({
                type: 'scatter',
                data: [{
                    x: item.x + 0.035,    // x position matches the column's x position plus a fudge factor
                    y: item.y,   // y position at the top of the column
                }],
                marker: {
                    symbol: 'circle', // The shape of the dot
                    radius: 5, // Size of the dot
                    fillColor: item.color
                },
                showInLegend: true,
                enableMouseTracking: false
            })),
        ],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
}
import React from 'react';
import FrequencyDefectChart from './FrequencyDefectChart';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export default function FrequencyDefect({ ingredient, allPositionSeriesData }) {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Typography variant="h5" style={styles.description}>
                {ingredient.IngredientDescription}
            </Typography>

            {/* Stack of charts rendered below the description */}
            {allPositionSeriesData.length > 0 ?
                (<Grid
                    container
                    direction="row"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    style={styles.grid}
                >
                    {allPositionSeriesData
                        .filter(positionData => positionData.seriesData && positionData.seriesData.length > 0)
                        .map((positionData, index) => (
                            <Grid item xs={6}>
                                <Item
                                    square={false}
                                    key={index}
                                >
                                    <Typography>{positionData.position}</Typography>
                                    <FrequencyDefectChart
                                        ingredient={ingredient}
                                        positionData={positionData}
                                    />
                                </Item>
                            </Grid>

                    ))}
                </Grid>) : (<Typography sx={{ my: "10%" }}>No data present for this ingredient.</Typography>) }
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        marginBottom: '16px', // Space between the description and the charts
    },
    grid: {
        justifyContent: 'center',  // Center the charts in the container
        alignItems: 'flex-start',  // Align the charts at the start
    },
};
import PhaseAngleDiagram from './PhaseAngleDiagram';
import Grid from '@mui/material/Grid';

export default function PoisitionAngle({ ingredient, phaseAngles, axisOrientationTypes }) {

    //Find the right label for each axis and place them on the graph correctly
    let vibrationAxes = []
    for (let i = 0; i < phaseAngles.length; i++) {
        
        let axisName = axisOrientationTypes.find(axis => axis.OrientationAxisID == phaseAngles[i].axisID && axis.OrientationID == phaseAngles[i].orientationID);
        let replacedDisplayName = ""
        if (phaseAngles[i].channelDisplayName.includes('X -Raw')) {
            replacedDisplayName = phaseAngles[i].channelDisplayName.replace('X -Raw', axisName.DisplayName)

        }
        if (phaseAngles[i].channelDisplayName.includes('Y -Raw')) {
            replacedDisplayName = phaseAngles[i].channelDisplayName.replace('Y -Raw', axisName.DisplayName)

        }
        if (phaseAngles[i].channelDisplayName.includes('Z -Raw')) {
            replacedDisplayName = phaseAngles[i].channelDisplayName.replace('Z -Raw', axisName.DisplayName)

        }
        vibrationAxes.push({
            ...phaseAngles[i],
            vibrationAxisID: axisName.ID,
            replacedDisplayName: replacedDisplayName

        })
    }
    let axial = vibrationAxes.find(axis => axis.vibrationAxisID == 4);
    let horizontal = vibrationAxes.find(axis => axis.vibrationAxisID == 5);
    let vertical = vibrationAxes.find(axis => axis.vibrationAxisID == 6);
    return (
        <>
            <Grid container direction="row">
                <Grid item xs={12}>
                    <PhaseAngleDiagram
                        angle={vertical?.angle}
                        positionText={vertical?.replacedDisplayName}
                        axisText={vertical?.angle + " " + ingredient.PrimaryComputedData.UnitTypeName}
                        color={vertical?.color}
                        orientation="vertical"
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhaseAngleDiagram
                        angle={horizontal?.angle}
                        positionText={horizontal?.replacedDisplayName}
                        axisText={horizontal?.angle + " " + ingredient.PrimaryComputedData.UnitTypeName}
                        color={horizontal?.color}
                        orientation="horizontal"
                    />
                </Grid>
                <Grid item xs={6}>
                    <PhaseAngleDiagram
                        angle={axial?.angle}
                        positionText={axial?.replacedDisplayName}
                        axisText={axial?.angle + " " + ingredient.PrimaryComputedData.UnitTypeName}
                        color={axial?.color}
                        orientation="axial"
                    />
                </Grid>
            </Grid>
        </>
    )
}
import React from 'react';
import { Paper, Grid } from '@mui/material';
import FormikSelectField from '../../Generic/FormikSelectField';
import FormikSwitch from '../../Generic/FormikSwitch';

const defectLevels = [
    {
        "value": 0,
        "label": "None"
    },
    {
        "value": 1,
        "label": "Minimal"
    },
    {
        "value": 2,
        "label": "Advanced"
    }
]

export default function VBTxConfigurationStep4(props) {

    return (
        <Paper elevation={0} sx={{ position: 'relative', width: '75%', margin: 'auto' }}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                rowSpacing={2}
            >
                <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSelectField
                        name={'defectLevel'}
                        label={'Desired Defect Classification Level'}
                        data={defectLevels}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["defectLevel"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>
                {props.formik?.values.defectLevel == 2 && <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                    <FormikSwitch
                        name={'maximizeNodes'}
                        label={'Maximize Nodes per ControlGate'}
                        onBlur={props.formik?.onBlur}
                        errors={props.formik?.errors["maximizeNodes"]}
                        width={'100%'}
                        enabled={'true'}
                    />
                </Grid>}
            </Grid>
        </Paper>
    )
}
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Typography, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import AmplitudeDefectElevated from './AmplitudeDefectElevated';
import AmplitudeDefectCharts from './AmplitudeDefectCharts';
import FrequencyDefect from './FrequencyDefect';
import PhaseDefect from './PhaseDefect';

export default function DefectIngredientModal({ ingredient, open, onClose, seriesData, machineType, axisOrientationTypes }) {
    const theme = useTheme();      

    /**
     * Determine which component to render based on IngredientTypeID
     */
    const renderDefectComponent = () => {
        if (!ingredient) {
            return null;
        }

        switch (ingredient.IngredientTypeID) {
            case 1:
                return <AmplitudeDefectElevated
                    ingredient={ingredient}                    
                    seriesData={seriesData} // Pass seriesData as prop
                />;
            case 2:
                return <AmplitudeDefectCharts
                    ingredient={ingredient}
                    allPositionSeriesData={seriesData}
                />;
            case 3:
                return <FrequencyDefect
                    ingredient={ingredient}
                    allPositionSeriesData={seriesData}
                />;
            case 4:
            case 5:
            case 6:
                return <PhaseDefect
                    ingredient={ingredient}
                    phaseAngleData={seriesData}
                    machineType={machineType}
                    axisOrientationTypes={axisOrientationTypes}
                />;
            default:
                return (
                    <Typography variant="h6" color="error">
                        No component available for this ingredient type.
                    </Typography>
                );
        }
    };


    return (        
        <Modal
            open={open}
            onClose={onClose}              
        >
            <Paper
                elevation={10}
                sx={{
                    position: 'relative',
                    padding: theme.spacing(2),
                    maxWidth: '90vw',           // Max width for responsiveness
                    maxHeight: '90vh',          // Max height for responsiveness
                    overflowY: 'auto',          // Scroll if content exceeds height
                    margin: 'auto',             // Ensure centered placement
                }}
            >                      
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                {renderDefectComponent()}
            </Paper>
        </Modal>
       
    );
}

import { Grid } from "@mui/material";
import { Box } from '@mui/system';
import React from "react";
import DefectDetailDropdown from "./DefectDetailDropdown";

// Expected Parameter: defects: Array, result set from master defect list
export default function Defects({ userID, defects, onDropdownChange, selectedValue, defaultRecipeStateID }) {

    //console.log(JSON.stringify(defects));

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Grid container spacing={2} align="center" justifyContent="center" alignItems="center" >
                <Grid item xs={12}>
                    <DefectDetailDropdown
                        data={defects}
                        userID={userID}
                        handleDropdownChange={onDropdownChange}
                        selectedValue={selectedValue}
                        defaultRecipeStateID={defaultRecipeStateID}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
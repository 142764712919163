import React, { useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export default function AmplitudeDefectElevated(props) {
    
    const handleColumnClick = (dashboardDataID) => {
        const redirectUrl = new URL(document.referrer);
        redirectUrl.pathname = '/Data';
        redirectUrl.searchParams.set('NodeChannelIDs', dashboardDataID);
        window.top.location = redirectUrl;
    };
    const formatThresholdSeries = (data, attr) => {
        let thresholdSeries = []
        let index = 0;
        data.forEach(d => {
            thresholdSeries.push([index - .5, d[attr]])
            index++;
        })
        if (thresholdSeries.length > 0) {
            thresholdSeries[0][0] += 0.01;
            thresholdSeries.push([thresholdSeries[thresholdSeries.length - 1][0] + 0.99, thresholdSeries[thresholdSeries.length - 1][1]]);
        }
        return thresholdSeries
    }

    const categories = props.seriesData.map(item => item.name);
    
    const chartOptions = {
        chart: {
            type: 'column',
        },
        title: {
            text: props.ingredient.IngredientDescription
        },
        xAxis: {
            categories: categories,
            labels: {
                autoRotationLimit: 40
            },
        },
        yAxis: {
            title: {
                text: `Vibration Amplitude (${props.ingredient.UnitTypeSymbol || 'ips'})`
            }
        },
        legend: {            
            enabled: false, // Enable the legend
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
        },
        tooltip: {
            formatter: function () {
                // Accessing custom properties from the data point
                return `<b>${this.point.name}</b>: ${this.point.y.toFixed(3)} (${ props.ingredient?.PrimaryComputedData.UnitTypeSymbol })<br/> ${this.point.channelDisplayName}`;
            }
        },
        plotOptions: {
            column: {
                grouping: false, // Ensures that columns don't overlap if there are multiple series
                pointPadding: 0.2, // Padding between columns
                groupPadding: 0.1, // Padding between groups of columns
                point: {
                    events: {
                        click: function () {
                            console.log(this.options);
                            handleColumnClick(this.options.dashboardDataID);
                        }
                    }
                }
            }
        },
        series: [
            ...props.seriesData.map((item, index) => ({
                type: 'column',
                name: item.name,    // Use the category name as the series name for legend
                data: [{
                    y: item.y,                 // The data point value
                    color: item.color,         // Use the specified color
                    x: index,
                    name: item.name,
                    channelDisplayName: item.channelDisplayName,  // Add the channelDisplayName to the point data
                    dashboardDataID: item.dashboardDataID
                }],
                showInLegend: true, 
                enableMouseTracking: true
            })),
            {
                type: 'line',  // Set the type to 'line' for this series
                name: 'warning', // Customize the name for the legend
                data: formatThresholdSeries(props.seriesData, "warningAlarmLevel"), 
                color: '#F0AD4E',  
                showInLegend: false,
                marker: {
                    enabled: false
                },
                enableMouseTracking: false
            }
        ], 
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 580
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                        layout: 'horizontal'
                    }
                }
            }]
        }
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    );
}

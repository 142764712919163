import React, { useEffect } from 'react';
import DefectInformation from './DefectInformation';
import DefectModalLabel from './DefectModalLabel';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    IconButton,
    Link, Modal,
    Typography
} from '@mui/material';

export default function DefectModal({ open, toggleModal, modalData, userID, selectedDefect }) {

    const getFrequencyGroupingText = (frequencyGrouping) => {
        switch (frequencyGrouping) {
            case 'S': return 'Synchronous';
            case 'Ss': return 'Sub-Synchronous';
            case 'H': return 'Harmonic';
            case 'Ns': return 'Non-Synchronous';
            case 'M': return 'Modulation';
            default: return '';
        }
    };

    const getDominantDirectionalGroupingText = (dominantDirectionalGrouping) => {
        switch (dominantDirectionalGrouping) {
            case 'R': return 'Radial';
            case 'A': return 'Axial';
            case 'R/A': return 'Radial and/or Axial';
            default: return '';
        }
    };

    return (
        <Modal
            open={open}
            onClose={toggleModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backdropFilter: 'brightness(0.7)',
            }}
        >
            <Box
                sx={{
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    borderRadius: '8px',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: 1,
                    borderColor: 'divider',
                    pb: 2
                }}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ pl: 2, color: 'text.primary' }}
                    >
                        {modalData?.DisplayName}{" "} (
                        {(modalData && modalData?.Symbol?.length === 2)
                            ? <>
                                {modalData?.Symbol?.charAt(0)}
                                <sub>{modalData?.Symbol?.charAt(1)}</sub>
                            </>
                            : modalData?.Symbol
                        }
                        )
                        <DefectModalLabel>
                            Frequency Grouping:{' '} {getFrequencyGroupingText(modalData.FrequencyGrouping)}{' '}
                            {(modalData && modalData?.FrequencyGrouping?.length === 2)
                                ? <>
                                    {'('}{modalData?.FrequencyGrouping?.charAt(0)}
                                    <sub>{modalData?.FrequencyGrouping?.charAt(1)}</sub>{')'}
                                </>
                                : <>{'('}{modalData?.FrequencyGrouping}{')'}</>
                            }
                        </DefectModalLabel>
                        <DefectModalLabel>
                            Dominant Directional Grouping: {' '} {getDominantDirectionalGroupingText(modalData.DominantDirectionalGrouping)}
                        </DefectModalLabel>
                        <DefectModalLabel>
                            Diagnostic Technique:{' '} {modalData?.Icon === 'TWF' ? 'Time Waveform Analysis' : `${modalData?.Icon} Analysis`}
                        </DefectModalLabel>
                    </Typography>
                    <IconButton aria-label="close" onClick={toggleModal}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Typography id="modal-modal-description" variant="body1" color="textSecondary">
                    <b>Description:</b>&nbsp;{modalData?.Description}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    {selectedDefect &&
                        <DefectInformation
                            recipeStateID={selectedDefect.RecipeStateID}
                            vibrationObjectID={selectedDefect.VibrationObjectID}
                            defectName={selectedDefect.DefectName}
                            likelihood={selectedDefect.Likelihood}
                            companyID={selectedDefect.CompanyID}
                            userID={userID}
                            colspan={2}
                            data={selectedDefect}
                        />
                    }
                </Box>
                <Typography sx={{ mt: 1, borderTop: 1, borderColor: 'divider', pt: 1 }}>
                    <Link
                        href={"https://fullspecdiagnostics.com/pages/vibration-fault-periodic-table-efficiency-software"}
                        underline="hover"
                        target="_blank">
                        Full Spectrum Diagnostics - Vibration Fault Periodic Table software
                    </Link>
                </Typography>
            </Box>
        </Modal>
    );
}


//Defect Type: Gear Mesh,
//Frequency Grouping: Harmonic,
//Directionality: Radial and / or Axial,
//Diagnostic Technique: Time Waveform Analysis,
//Indicators: Blah,
//Counter Indicators: Blah,
//Missing Indicators: Blah
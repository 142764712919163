import React from 'react';
import AmplitudeDefectDominant from './AmplitudeDefectDominant';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export default function AmplitudeDefectCharts({ ingredient, allPositionSeriesData }) {

    // Function to calculate the width of each item based on the number of positions
    const calculateItemWidth = (numItems) => {
        // 80% of the total width divided by the number of items
        const totalWidth = 80; // 80% of the container width
        const itemWidth = totalWidth / numItems;
        return `${itemWidth}%`; // Return as percentage string
    };

    // Number of valid positions with seriesData
    const validPositionsCount = allPositionSeriesData.filter(positionData => positionData.seriesData && positionData.seriesData.length > 0).length;

    const Item = styled(Paper)(({ theme }) => ({
        width: calculateItemWidth(validPositionsCount), // Dynamic width based on the number of items
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        ...theme.applyStyles('dark', {
            backgroundColor: '#1A2027',
        }),
    }));

    return (
        <div style={styles.container}>
            {/* Description that stretches across the top */}
            <Typography variant="h5" style={styles.description}>
                {ingredient.IngredientDescription}
            </Typography>

            {/* Stack of charts rendered below the description */}
            {allPositionSeriesData.length > 0 ? (
                <Stack
                    direction="row"
                    spacing={{ xs: 1, sm: 2, md: 4 }}
                    style={styles.stack}
                >
                    {allPositionSeriesData
                        .filter(positionData => positionData.seriesData && positionData.seriesData.length > 0)
                        .map((positionData, index) => (
                            <Item
                                square={false}
                                key={index}
                            >
                                <Typography>{positionData.position}</Typography>
                                <AmplitudeDefectDominant
                                    ingredient={ingredient}
                                    seriesData={positionData.seriesData}
                                />
                            </Item>
                        ))}
                </Stack>) : (<Typography sx={{my: "10%"}}>No data present for this ingredient.</Typography>)}
        </div>
    );
}

const styles = {
    container: {
        width: '100%',        // Ensure the container takes full width
        textAlign: 'center',  // Center the content
        margin: '0 auto',     // Center the container horizontally
    },
    description: {
        width: '100%',        // Make sure the description spans across the entire width
        marginBottom: '16px', // Space between the description and the charts
    },
    stack: {
        justifyContent: 'center',  // Center the charts in the container
        alignItems: 'flex-start',  // Align the charts at the start
    },
};


import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#30A8FF', // Blue
            contrastText: '#fff', // Add contrastText to ensure readability
        },
        secondary: {
            main: '#555', // Gray
            contrastText: '#fff', // Add contrastText to ensure readability
        },
        background: {
            default: '#ffffff',
            paper: '#ffffff',
        },
        info: {
            main: '#17A2B8',
            contrastText: '#fff',
        },
        success: {
            main: '#28A745',
            contrastText: '#fff',
        },
        warning: {
            main: '#FFC107',
            contrastText: '#fff',
        },
        error: {
            main: '#D70404',
            contrastText: '#fff',
        },
        grey: {
            main: "#A9A9A9",
        },
        element: {
            radial: '#E66100',
            axial: '#CC9C33',
            either: '#40B0A6',
            neither: '#E1BE6A',
            white: '#ffffff'
        }
    },
    typography: {
        fontFamily: 'Eurostile',
    },
    spacing: 8,
    components: {
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'uppercase',
                    //fontSize: '1.5rem',
                },
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                color: 'success',
            },
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    width: 32,
                    height: 32,
                    marginLeft: '-0.5rem',
                    marginRight: '1rem',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    elevation: 3,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    marginTop: '1.5rem',
                    overflowY: 'auto',  // Enable vertical scrolling when needed
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: '#555', // Use secondary color directly
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.modal-paper': {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 700,
                        maxHeight: '480px',
                        overflow: 'auto',
                        padding: '16px', // Use static value
                        backgroundColor: '#ffffff',
                        boxShadow: '24px',
                        outline: 'none',
                    },
                    '&.modal-paper-long': {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 800,
                        maxHeight: '800px',
                        padding: '16px', // Use static value
                        backgroundColor: '#ffffff',
                        boxShadow: '24px',
                        outline: 'none',
                        display: 'flex',
                        flexDirection: 'column'
                    },
                },
                '&.modal-paper-large': {
                    position: 'absolute',
                    top: '10%',
                    left: '10%',
                    width: '80%', // 100% - 10% (left) - 10% (right) = 80%
                    height: '80%', // 100% - 10% (top) - 10% (bottom) = 80%
                    overflow: 'auto',
                    padding: '16px',
                    backgroundColor: '#ffffff',
                    boxShadow: '24px',
                    outline: 'none',
                },
            },
        },
    },
});

export default theme;

import { Box, Typography } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import React, { createContext, useState } from "react";
import Data from "./data.json";
import Modal from "./DefectModal";
import DefectPeriodicTableGrid from "./DefectPeriodicTableGrid";

const ModalContext = createContext();

// Expected Parameter: defects: Array, result set from master defect list
export default function DefectPeriodTable({
    userID, activeRecipeID, defectRecipes, defaultRecipeStateID, selectedValue, handleElementChange })
{
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [textColor] = useState(blueGrey);

    const toggleModal = (element) => {
        setModalData(element)
        setOpen((prevState) => !prevState);
    }

    return (
        <ModalContext.Provider value={toggleModal}>
            <Box>
                <Modal
                    open={open}
                    toggleModal={toggleModal}
                    modalData={modalData}
                    userID={userID}
                    selectedDefect={selectedValue}
                />

                <Typography variant="h5" className={`md:hidden text-${textColor} font-semibold`}>Defects</Typography>
                <DefectPeriodicTableGrid
                    tableElements={Data.elements}
                    recipeID={activeRecipeID}
                    activeDefects={defectRecipes}
                    defaultRecipeStateID={defaultRecipeStateID}
                    handleElementChange={handleElementChange}
                    userID={userID}
                />
            </Box>
        </ModalContext.Provider>

    )
}

export { ModalContext };
